@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

footer {
	z-index: 100000;
	text-align: center;
	padding: 1px;
	height: 40px;
	font-size: x-small;
	background-color: #33689c;
	color: white;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
* {
	outline: none !important;
}

html,
body {
	min-width: 375px;
	min-height: 100vh;
}

body {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	background-color: #fafafa;
	color: #767676;
	-ms-overflow-style: scrollbar;
	-webkit-font-smoothing: subpixel-antialiased;
	font-family: "Roboto", sans-serif;
}

.wrapper {
	min-height: 100vh;
}

a {
	color: #000;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.2em;
	padding: 0;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
	display: block;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	-webkit-appearance: none;
	appearance: none;
}

::-webkit-input-placeholder {
	color: #000;
}

::-moz-placeholder {
	color: #000;
}

:-ms-input-placeholder {
	color: #000;
}

:-moz-placeholder {
	color: #000;
}

::-moz-focus-inner {
	border: 0;
}

select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}

option:not(:checked) {
	color: black;
}

p {
	padding: 0;
}

body {
	margin: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	-webkit-appearance: none;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.mb-ng-8 {
	margin-bottom: -8px !important;
}
.ml-ng-10 {
	margin-left: -10px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mx-4 {
	margin: 0 4px;
}

.password-icon,
.blue-icon {
	color: #33689c;
}

.icon-primary-color {
	color: #767676 !important;
}

.w-full {
	width: 100%;
}

.text-center {
	text-align: center;
}

.primary-link {
	text-align: center;
	transition: all 0.3s;
	position: relative;
}

.primary-link::after {
	content: "";
	position: absolute;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	height: 1px;
	background-color: #034f8b;
	width: 0;
	transition: all 0.3s;
}

.primary-link:hover::after,
.primary-link:focus::after {
	width: 100%;
}

.datepicker-wrappper .MuiStack-root {
	padding-top: 0;
	overflow: visible;
}

.section-heading {
	color: #565656;
}

.text-light-grey {
	color: #a1a1a1;
}

.text-dark-black {
	color: #292929;
}

.text-wrap {
	white-space: normal !important;
	width: 500px;
}

.d-flex {
	display: flex;
}

.d-block {
	display: block;
}

.align-item-center {
	align-items: center;
}

.justify-content-center {
	justify-content: center;
}

.main-content {
	background-color: #f7f7f8;
}

.page-content {
	transition: all 0.3s;
	position: relative;
	padding-top: 74px;
	min-height: 100vh;
	padding-left: 234px;
}

.hide-label .page-content {
	padding-left: 78px;
}

.page-inner {
	padding: 20px;
	min-height: calc(100vh - 74px);
}

.page-heading {
	font-size: 24px;
	line-height: 30px;
	font-weight: 500;
	color: #575757;
	margin-right: auto;
}

.page-heading-wrapper {
	display: flex;
	align-items: center;
	gap: 12px;
	padding-bottom: 20px;
}

.white-paper {
	background: #ffffff;
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	padding: 20px;
	display: block;
}

.table-negative-space {
	margin: 0 -20px;
}

.table-heading-block {
	display: flex;
	align-items: center;
	padding-bottom: 20px;
}

.table-heading-block .MuiTextField-root {
	margin-left: auto;
}
td.remark-col.MuiTableCell-root {
	white-space: normal;
	max-width: 360px;
	width: 100%;
	min-width: 300px;
}
td.remark-col.MuiTableCell-root .MuiLink-root {
	white-space: normal;
	max-width: 360px;
	width: 100%;
	display: block;
	min-width: 300px;
}
.pagination-wrapper {
	padding-top: 16px;
	margin-bottom: -4px;
}
.remark-col .MuiLink-root {
	color: #767676;
	text-decoration: none;
}

/* Radio
--------------------------------------------------------------------------------------------------------*/
.grid-radio.MuiFormControl-root {
	margin-bottom: -6px;
}

.grid-radio .MuiFormControlLabel-root {
	margin-left: -6px;
}

.grid-radio .MuiRadio-root {
	padding: 4px;
}

/* File Upload
--------------------------------------------------------------------------------------------------------*/
.upload input {
	display: none;
}

.upload .MuiInputBase-root {
	width: 0;
}

.upload .MuiButtonBase-root {
	padding: 10px 20px;
}

.upload .MuiButtonBase-root .MuiSvgIcon-root {
	font-size: 24px;
}

/* Custom Scrollbar
--------------------------------------------------------------------------------------------------------*/
/* width */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #cccccc;
	border-radius: 8px;
}

/* Table
--------------------------------------------------------------------------------------------------------*/
.MuiDialogContent-root .section-heading {
	padding-bottom: 12px;
}

.add-delete-row-table .MuiTableHead-root .MuiTableCell-root:last-child,
.add-delete-row-table .MuiTableBody-root .MuiTableCell-root:last-child {
	width: 70px;
	text-align: center;
}

.add-delete-row-table .MuiTableBody-root tr.MuiTableRow-root,
.payment-option .MuiTableBody-root tr.MuiTableRow-root {
	background-color: #fff !important;
}

.add-delete-row-table .MuiTableBody-root .MuiTableRow-root:not(:last-child) {
	border-bottom: 1px solid #bdbdbd;
}

.add-delete-row-table .MuiTableBody-root .MuiTableCell-body {
	padding: 10px 12px;
}

.add-delete-row-table .MuiTableHead-root .MuiTableCell-root .MuiSvgIcon-root {
	color: #fff;
}

.add-delete-row-table .MuiTableCell-root .MuiSvgIcon-root {
	height: 20px;
	width: 20px;
}

.add-delete-row-table .MuiFormControl-root,
.payment-option .MuiFormControl-root {
	min-width: 180px;
}
.payment-option
	.MuiTableBody-root
	.MuiTableCell-root:first-child
	.MuiFormControl-root {
	min-width: 210px;
}
.add-delete-row-table .MuiInputBase-root .MuiOutlinedInput-input,
.payment-option .MuiInputBase-root .MuiOutlinedInput-input {
	padding: 4px 12px;
	height: 36px;
}

.add-delete-row-table .MuiTextField-root .MuiFormLabel-root.MuiInputLabel-root,
.payment-option .MuiTextField-root .MuiFormLabel-root.MuiInputLabel-root,
.payment-option .MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-root {
	top: -7px;
}

.add-delete-row-table
	.MuiTextField-root
	.MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-filled,
.add-delete-row-table
	.MuiTextField-root
	.MuiFormLabel-root.MuiInputLabel-root.Mui-focused,
.payment-option
	.MuiTextField-root
	.MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-filled,
.payment-option
	.MuiTextField-root
	.MuiFormLabel-root.MuiInputLabel-root.Mui-focused,
.payment-option
	.MuiFormControl-root
	.MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-filled,
.payment-option
	.MuiFormControl-root
	.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
	transform: translate(14px, 1px) scale(0.75);
}

/* Login 
--------------------------------------------------------------------------------------------------------*/
.login-wrapper {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-form {
	max-width: 500px;
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	margin: 16px;
	padding: 60px;
}

.login-form .logo {
	margin-bottom: 0px;
	display: block;
}

.login-form .logo .MuiAvatar-root {
	height: auto;
	width: 170px;
	margin: 0 auto;
}

/* Header
--------------------------------------------------------------------------------------------------------*/
.header {
	background-color: #ffffff;
	display: flex;
	align-items: center;
	padding: 12px 16px;
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 21;
}

.hamburger-btn .MuiSvgIcon-root {
	height: 30px;
	width: 30px;
}

.profile-btn.MuiButtonBase-root {
	margin-left: auto;
	border-radius: 4px;
}

.profile-btn .MuiTypography-body1 {
	margin: 0 10px;
}

/* Sidebar
--------------------------------------------------------------------------------------------------------*/
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 234px;
	background-color: #fff;
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.08);
	top: 74px;
	overflow-y: auto;
	padding: 10px 8px;
	transition: all 0.3s;
	height: calc(100vh - 74px);
	z-index: 20;
}

.sidebar li:not(:last-child) {
	padding-bottom: 2px;
}

.sidebar li .MuiLink-root {
	text-decoration: none;
	color: #767676;
}

.sidebar > li > .MuiLink-root {
	display: flex;
	align-items: center;
	padding: 12px 8px;
	border-radius: 4px;
	transition: all 0.3s;
}

.sidebar > li {
	position: relative;
}

.sidebar > li.has-menu > .MuiLink-root {
	background-image: url("../src/assets/images/arrow-down-default-icon.svg");
	background-size: 12px;
	background-position: right 7px center;
	background-repeat: no-repeat;
	transition: all 0.3s;
}

.sidebar li .MuiLink-root .MuiSvgIcon-root {
	transition: all 0.3s;
}

.sidebar li .MuiLink-root .MuiTypography-root {
	margin-left: 8px;
	transition: all 0.3s;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: calc(100% - 42px);
}

.sidebar > li.has-menu > .MuiLink-root:hover,
.sidebar > li.has-menu > .MuiLink-root:focus {
	background-image: url("../src/assets/images/arrow-down-dark-grey-icon.svg");
}

.sidebar > li.has-menu.open > .MuiLink-root {
	background-image: url("../src/assets/images/arrow-up-primary-icon.svg");
}

.sidebar li .MuiLink-root:hover,
.sidebar li .MuiLink-root:focus {
	color: #33689c;
}

.sidebar li .MuiLink-root:active,
.sidebar li .MuiLink-root.active {
	color: #33689c;
}

.sidebar > li > .MuiLink-root.active {
	background-color: #d8d9f3;
}

.sidebar > li > ul {
	padding: 4px 0;
	display: none;
}

.sidebar > li.has-menu.open > ul {
	display: block;
}

.sidebar > li > ul li {
	padding-left: 40px;
}

.sidebar > li > ul li .MuiLink-root {
	display: block;
	padding: 8px 12px;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	width: 100%;
	transition: all 0.3s;
	color: #767676;
}

.sidebar > li > ul li .MuiLink-root:hover,
.sidebar > li > ul li .MuiLink-root:focus,
.sidebar > li > ul li .MuiLink-root.active {
	color: #034f8b;
}

.sidebar > li > ul li .MuiLink-root::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: 4px;
	width: 4px;
	background-color: #767676;
	border-radius: 50%;
}

.sidebar > li > ul li .MuiLink-root:hover:after,
.sidebar > li > ul li .MuiLink-root.active:after,
.sidebar > li > ul li .MuiLink-root:focus:after {
	background-color: #034f8b;
	border-color: #034f8b;
}

.hide-label .sidebar {
	width: 78px;
	padding: 12px;
	overflow-y: visible;
	height: auto;
	position: absolute;
}

.hide-label .sidebar > li > .MuiLink-root {
	width: 100%;
	padding: 14px 16px;
}

.hide-label .sidebar > li:hover > .MuiLink-root {
	background-color: #d8d9f3;
	width: 254px;
	position: relative;
	transition: none;
	z-index: 1;
}

.hide-label .sidebar li .MuiLink-root .MuiTypography-root {
	margin-left: 0;
}

.hide-label .sidebar li:hover .MuiLink-root {
	color: #034f8b;
}

.hide-label .sidebar li:hover .MuiLink-root .MuiTypography-root {
	margin-left: 44px;
}

.hide-label .sidebar > li.has-menu.open > ul {
	display: none;
}

.hide-label .sidebar > li.has-menu:hover > ul {
	display: block;
	position: absolute;
	left: 67px;
	width: 186px;
	background-color: #fff;
	box-shadow: 2px 0px 7px 2px rgba(0, 0, 0, 0.2);
}

.hide-label .sidebar > li:last-child.has-menu:hover > ul {
	bottom: 50px;
	padding-top: 0;
	padding-bottom: 4px;
}

.hide-label .sidebar > li > ul {
	max-height: 364px;
	overflow-y: auto;
}

.hide-label .sidebar > li:hover > ul li {
	padding-left: 0;
}

.hide-label .sidebar > li:hover > ul li .MuiLink-root {
	padding: 8px 16px;
	color: #767676;
}

.hide-label .sidebar > li:hover > ul li .MuiLink-root:hover {
	color: #034f8b;
}

.hide-label .sidebar > li:hover > ul li .MuiLink-root::after {
	display: none;
}

.hide-label .sidebar > li.has-menu > .MuiLink-root {
	background-image: none;
}

/* Dashboard
---------------------------------------------------------------------------------------------------------------*/
.statistic-card {
	position: relative;
	overflow: hidden;
}

.statistic-card::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 0;
	background-color: rgba(3, 79, 139, 0.1);
	transition: all 0.5s;
}
.statistic-card::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 4px;
	background-color: #034f8b;
}
.statistic-card:hover:before {
	width: 100%;
}

.statistic-card .MuiTypography-h6 {
	color: #034f8b;
	position: relative;
	z-index: 1;
}

.statistic-card .MuiTypography-body1 {
	color: #575757;
	font-weight: 500;
	position: relative;
	z-index: 1;
}

.statistic-cardActive {
	position: relative;
	overflow: hidden;
}

.statistic-cardActive::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	background-color: rgba(3, 79, 139, 0.1);
	transition: all 0.5s;
}
.statistic-cardActive::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 4px;
	background-color: #034f8b;
}

.statistic-cardActive .MuiTypography-h6 {
	color: #034f8b;
	position: relative;
	z-index: 1;
}

.statistic-cardActive .MuiTypography-body1 {
	color: #575757;
	font-weight: 500;
	position: relative;
	z-index: 1;
}

/* Responsive Media Query
---------------------------------------------------------------------------------------------------------------*/
@media (max-width: 1535px) {
}

@media (max-width: 1199px) {
}

@media (max-width: 899px) {
	.header {
		padding: 12px;
		z-index: 21;
	}

	/* Sidebar */
	.sidebar {
		position: fixed;
		top: 74px;
		left: -240px;
		bottom: 0;
		z-index: 2;
	}

	.show-mobile-menu {
		overflow: hidden;
	}

	.show-mobile-menu .sidebar {
		width: 234px;
		padding: 10px 8px;
		left: 0;
		overflow: auto;
	}

	.show-mobile-menu .sidebar > li:hover > .MuiLink-root {
		width: auto;
	}

	.show-mobile-menu .sidebar li .MuiLink-root .MuiTypography-root {
		margin-left: 8px !important;
	}

	.show-mobile-menu .sidebar > li.has-menu > .MuiLink-root {
		background-image: url("../src/assets/images/arrow-down-default-icon.svg");
	}

	.show-mobile-menu .sidebar > li.has-menu.open > .MuiLink-root {
		background-image: url("../src/assets/images/arrow-up-primary-icon.svg");
	}

	.show-mobile-menu .sidebar > li.has-menu > ul {
		position: static !important;
		box-shadow: none !important;
	}

	.show-mobile-menu .sidebar > li.has-menu:hover > ul {
		display: none;
	}

	.show-mobile-menu .sidebar > li:hover > ul li {
		padding-left: 38px;
	}

	.show-mobile-menu .sidebar > li > ul li .MuiLink-root {
		height: auto;
		padding: 8px 11px !important;
		color: #767676 !important;
	}

	.show-mobile-menu .sidebar > li > ul li .MuiLink-root:hover,
	.show-mobile-menu .sidebar > li > ul li .MuiLink-root:focus,
	.show-mobile-menu .sidebar > li > ul li .MuiLink-root.active {
		color: #034f8b !important;
	}

	.show-mobile-menu .sidebar > li:hover > ul li .MuiLink-root::after {
		display: block;
	}

	.show-mobile-menu .sidebar > li.has-menu.open > ul {
		display: block;
		width: auto;
	}

	.show-mobile-menu .sidebar > li:hover > .MuiLink-root {
		background-color: transparent;
	}

	.show-mobile-menu .sidebar > li:hover > .MuiLink-root.active {
		background-color: #e0e7f1;
	}

	.show-mobile-menu .sidebar > li > .MuiLink-root {
		padding: 12px 8px;
	}

	.hide-label .sidebar > li:last-child.has-menu:hover > ul {
		padding-top: 2px;
		padding-bottom: 0;
		bottom: auto;
	}

	.hide-label .page-content,
	.page-content {
		padding-left: 0;
	}

	.MuiButtonBase-root.hamburger-btn {
		margin-left: -4px;
	}
}

@media (max-width: 599px) {
	.login-form {
		padding: 40px 16px;
	}

	.profile-btn.MuiButtonBase-root {
		min-width: auto;
		border-radius: 50%;
		padding: 4px;
	}

	.profile-btn .MuiTypography-body1,
	.profile-btn .MuiTypography-body1 + .MuiAvatar-root {
		display: none;
	}

	.login-form .logo .MuiAvatar-root {
		width: 140px;
	}

	.header {
		padding: 8px;
	}

	.page-content {
		padding-top: 62px;
	}

	.page-inner {
		min-height: calc(100vh - 62px);
		padding: 16px;
	}

	.page-heading {
		font-size: 20px;
		line-height: 26px;
	}

	.MuiIconButton-root.primary-icon.MuiButtonBase-root {
		padding: 4px;
	}

	.page-heading-wrapper {
		padding-bottom: 16px;
	}

	.white-paper {
		padding: 16px;
	}

	.table-negative-space {
		margin: 0 -16px;
	}

	.sidebar {
		top: 62px;
	}

	.pagination-wrapper .MuiTablePagination-root .MuiInputBase-root {
		margin-right: 8px;
		margin-left: 0;
	}

	.pagination-wrapper .MuiTablePagination-toolbar .MuiTablePagination-actions {
		margin-left: 8px;
	}

	.MuiTablePagination-root .MuiToolbar-root {
		padding-left: 0;
	}

	.table-heading-block {
		padding-bottom: 16px;
	}

	.table-heading-block .MuiTextField-root {
		margin-left: 0;
		width: 100%;
	}
}
